//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Step2',
  components: {
    'parsed-task': () => import('./ParsedTask'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    nothingFound: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      taskItemSize: 64,
    }
  },
  methods: {
    handleChange (importVal, itemIndex) {
      const newItems = [...this.value]
      newItems[itemIndex].import = importVal
      this.$emit('input', newItems)
    },
  },
}
